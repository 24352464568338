import { useState } from "react";
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from "@mantine/core";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../utils/firebase";
import { useInputState } from "@mantine/hooks";
import { useNotifications } from "@mantine/notifications";

const Auth = () => {

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container style={{ width: "420px" }}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Welcome back!
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Sign in to continue
        </Text>
        {/* <Text color="dimmed" size="sm" align="center" mt={5}>
          Do not have an account yet?{" "}
          <Anchor
            href="#"
            size="sm"
            onClick={(event: any) => {
              event.preventDefault();
              changePage();
            }}
          >
            {page === 1 ? "Create account" : "Sign in"}
          </Anchor>
        </Text> */}

        <SignIn />
      </Container>
    </div>
  );
};

export default Auth;

const SignIn = () => {
  const [email, setEmail] = useInputState("");
  const [password, setPassword] = useInputState("");
  const notifications = useNotifications();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error: any) {
      notifications.showNotification({
        title: "There was an error",
        message: "Failed to sign in," + error?.message,
        color: "red",
      });
      console.log(error?.message);
      setLoading(false);
    }
  };

  return (
    <Paper withBorder shadow="md" p={30} mt={30} radius="md">
      <TextInput
        value={email}
        onChange={setEmail}
        label="Email"
        placeholder="you@email.com"
        required
      />
      <PasswordInput
        value={password}
        onChange={setPassword}
        label="Password"
        placeholder="Your password"
        required
        mt="md"
      />
      <Group position="apart" mt="md">
        <Checkbox label="Remember me" />
        <Anchor
          onClick={(event: any) => event.preventDefault()}
          href="#"
          size="sm"
        >
          Forgot password?
        </Anchor>
      </Group>
      <Button fullWidth mt="xl" loading={loading} onClick={handleSubmit}>
        Sign in
      </Button>
    </Paper>
  );
};
