import React from "react";
import {
  Button,
  Group,
  Menu,
  Paper,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { signOut } from "firebase/auth";
import { ChevronDown, Logout } from "tabler-icons-react";
import { auth } from "../utils/firebase";
import { useLocation, useNavigate } from "react-router-dom";

const TopNav = ({ user }: { user: any }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActivePath = (path: string) => pathname === path;
  return (
    <Paper shadow="sm" p="md" withBorder>
      <Group position="apart">
        <Title
          order={2}
          onClick={() => navigate("/")}
          style={{ cursor: "pointer", color: "#228be6", userSelect: "none" }}
        >
          Aria Noir
        </Title>
        <Group>
          <Button
            variant={isActivePath("/") ? "light" : "subtle"}
            onClick={() => navigate("/")}
          >
            Home
          </Button>
          <Button
            variant={isActivePath("/whitelist") ? "light" : "subtle"}
            onClick={() => navigate("/whitelist")}
          >
            Whitelist
          </Button>
        </Group>
        <Menu
          size={260}
          placement="end"
          transition="pop-top-right"
          control={
            <UnstyledButton>
              <Group spacing={7}>
                <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                  {user.email}
                </Text>
                <ChevronDown size={12} />
              </Group>
            </UnstyledButton>
          }
        >
          <Menu.Label>Settings</Menu.Label>
          <Menu.Item onClick={() => signOut(auth)} icon={<Logout size={14} />}>
            Logout
          </Menu.Item>
        </Menu>
      </Group>
    </Paper>
  );
};

export default TopNav;
