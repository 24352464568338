import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/Error/NotFound";
import Home from "./pages/Home/Home";
import { auth } from "./utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  Container,
  LoadingOverlay,
  Space,
} from "@mantine/core";
import { AuthContext } from "./utils/AuthContext";
import Auth from "./pages/Auth/Auth";
import TopNav from "./components/TopNav";
import Invite from "./pages/Invite/Invite";
import Whitelist from "./pages/Whitelist/Whitelist";

function App() {
  const [user, loading, error]: any = useAuthState(auth);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="App">
      <LoadingOverlay visible={loading} />
      {!user && !loading && <Auth />}
      {user && (
        <AuthContext.Provider value={user}>
          <Container>
            <TopNav user={user} />
            <Space h="xl" />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/whitelist" element={<Whitelist />} />
              <Route path="/invites/:id" element={<Invite />} />
              <Route path="*" element={<NotFound />} />
            </Routes>{" "}
          </Container>
        </AuthContext.Provider>
      )}
    </div>
  );
}

export default App;
