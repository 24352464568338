import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Checkbox,
  Group,
  Input,
  List,
  Loader,
  Modal,
  Radio,
  RadioGroup,
  Space,
  Table,
  Title,
} from "@mantine/core";
// import { showNotification } from "@mantine/notifications";
import {
  AlertCircle,
  Check,
  CircleX,
  ExclamationMark,
  ExternalLink,
  Search,
} from "tabler-icons-react";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { showNotification } from "@mantine/notifications";
import { ethers } from "ethers";
import AriaNoir from "../../utils/AriaNoir.json";
import { CONTRACT_ADDRESS, METADATA_BASE_URI } from "../../utils/strings";
import { sendConfirmation } from "../../utils/repository";

const Whitelist = () => {
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [invites, setInvites] = useState<any[]>([]);
  const [selected, setSelected] = useState<boolean[]>([]);
  const [whitelisting, setWhitelisting] = useState(false);
  const [showWhitelistModal, setShowWhitelistModal] = useState(false);
  const [wallet, setWallet] = useState();
  const [method, setMethod] = useState("");
  const [invitesQ, invitesLoading, inviteError] = useCollection(
    query(
      collection(db, `invites`),
      where("status", "!=", "PENDING_ADDRESS"),
      orderBy("status"),
      orderBy("created_at", "desc")
    )
  );
  useEffect(() => {
    if (invitesQ) {
      const out = [];
      for (const doc of invitesQ.docs) out.push({ ...doc.data(), id: doc.id });
      setInvites(filter(search, out));
    }
  }, [invitesQ, search]);

  const filter = (q: string, items: any[]) => {
    setSelected([]);
    const out = [];
    for (const item of items) {
      if (
        item.email?.toLowerCase().includes(q.toLowerCase()) ||
        item.name?.toLowerCase().includes(q.toLowerCase()) ||
        item.sku?.toLowerCase().includes(q.toLowerCase()) ||
        item.status?.toLowerCase().includes(q.toLowerCase())
      ) {
        out.push(item);
      }
    }
    return out;
  };

  const toggleSelect = (index: number, state: boolean) => {
    const newSelected = [...selected];
    newSelected[index] = state;
    setSelected(newSelected);
  };

  const toggleSelectAll = (state: boolean) => {
    const newSelected = [];
    for (let i = 0; i < invites.length; i++) {
      const invite = invites[i];
      newSelected.push(
        invite.status === "PENDING_WHITELISTING" ? state : false
      );
    }
    setSelected(newSelected);
  };

  const isAllSelected = () => {
    if (selected.length === 0) return false;
    for (let i = 0; i < selected.length; i++) {
      if (selected[i]) return true;
    }
    return false;
  };

  const whitelist = async () => {
    setWhitelisting(true);
    const addresses: string[] = [];
    const tokens: number[] = [];
    const meta: string[] = [];
    if (!method) {
      showNotification({
        title: "Error",
        message: "Select a method",
        icon: <ExclamationMark />,
        color: "yellow",
      });
      return;
    }

    if (method === "Whitelist") {
      try {
        const batch = writeBatch(db);
        const sent = [];
        for (let i = 0; i < selected.length; i++) {
          if (selected[i]) {
            const invite = invites[i];
            addresses.push(invite.address);
            tokens.push(invite.token_id);
            meta.push(`${METADATA_BASE_URI}/${invite.metadata}`);
            sent.push(invite);
            const ref = doc(db, `invites/${invite.id}`);
            batch.update(ref, { status: "WHITELISTED" });
          }
        }
        const status = await updateContract(addresses, tokens, meta);

        if (!status) throw new Error("Whitelist failed");
        await batch.commit();
        for (let i = 0; i < sent.length; i++) {
          await sendConfirmation(sent[i].id, method);
        }
        showNotification({
          title: "Action Successful",
          message: "Whitelisting was Successful",
          icon: <Check />,
          color: "green",
        });
      } catch (error: any) {
        console.log(error);
        showNotification({
          title: "Action failed",
          message: "There was an error! " + (error.message ?? ""),
          icon: <CircleX />,
          color: "red",
        });
      }
    } else if (method === "Gift") {
      try {
        const batch = writeBatch(db);
        const sent = [];
        for (let i = 0; i < selected.length; i++) {
          if (selected[i]) {
            const invite = invites[i];
            addresses.push(invite.address);
            tokens.push(invite.token_id);
            meta.push(`${METADATA_BASE_URI}/${invite.metadata}`);
            sent.push(invite);
            const ref = doc(db, `invites/${invite.id}`);
            batch.update(ref, { status: "GIFTED" });
          }
        }
        const status = await giftNFT(addresses, tokens, meta);
        if (!status) throw new Error("Gift failed");
        await batch.commit();
        for (let i = 0; i < sent.length; i++) {
          await sendConfirmation(sent[i].id, method);
        }
        showNotification({
          title: "Action Successful",
          message: "Gifted NFTs successfully.",
          icon: <Check />,
          color: "green",
        });
      } catch (error: any) {
        // console.log(error);
        showNotification({
          title: "Action failed",
          message: "There was an error! " + (error.code ?? ""),
          icon: <CircleX />,
          color: "red",
        });
      }
    }
    setShowWhitelistModal(false);
    setWhitelisting(false);
  };

  const installMetamask = () => {
    const res = window.confirm(
      "Metamask wallet is not installed, install now?"
    );
    if (res) window.open("https://metamask.io/download/");
  };

  const requestAccount = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setWallet(accounts[0]);
      } catch (error) {
        console.error(error);
      }
    } else {
      installMetamask();
      console.log("Metamask not installed!");
    }
  };

  const updateContract = async (
    addresses: string[],
    tokens: number[],
    meta: string[]
  ) => {
    if (window.ethereum) {
      await requestAccount();
      if (wallet) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          CONTRACT_ADDRESS,
          AriaNoir.abi,
          signer
        );
        const response = await contract.updateWhitelist(
          addresses,
          tokens,
          meta
        );
        console.log(response);
        return true;
      }
    } else {
      installMetamask();
      console.log("Metamask not installed!");
    }
    return false;
  };

  const giftNFT = async (
    addresses: string[],
    tokens: number[],
    meta: string[]
  ) => {
    if (window.ethereum) {
      await requestAccount();
      if (wallet) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          CONTRACT_ADDRESS,
          AriaNoir.abi,
          signer
        );
        const response = await contract.giftToken(addresses, tokens, meta);
        console.log(response);
        return true;
      }
    } else {
      installMetamask();
      console.log("Metamask not installed!");
    }
    return false;
  };

  const closeWhitelistModal = () => {
    setShowWhitelistModal(false);
  };

  return (
    <>
      <Modal
        opened={showWhitelistModal}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={closeWhitelistModal}
        title={`${selected.length} selected user${
          selected.length === 1 ? "" : "s"
        }, Choose the method of whitelisting`}
      >
        <List size="xs" spacing="sm" center>
          <List.Item>
            <strong>Whitelist option</strong> will whitelist the selected wallet
            addresses, the users will need mint the NFT by paying GAS fees
            themselves. This action will also incur a GAS fee.
          </List.Item>
          <List.Item>
            <strong>Gift option</strong> will gift NFT to the selected wallet
            addresses. The GAS fee of the NFT minting will be added to the
            current GAS fee.
          </List.Item>
        </List>
        <Space h="md" />
        <RadioGroup onChange={(e) => setMethod(e)} value={method}>
          <Radio value="Whitelist" label="Whitelist" />
          <Radio value="Gift" label="Gift" about="tes" />
        </RadioGroup>
        <Group position="right" mt="md">
          <Button disabled={!method} loading={whitelisting} onClick={whitelist}>
            {method && method !== "" ? method : "Select a method"}
          </Button>
        </Group>
      </Modal>
      <Space h="md" />
      <Group position="apart">
        <Title order={3}>Ready to be whitelisted</Title>
        <Group>
          <Input
            icon={<Search />}
            placeholder="Search..."
            onChange={(e: any) => setSearch(e.target.value)}
          />
          {!wallet ? (
            <Button loading={whitelisting} onClick={requestAccount}>
              Connect Wallet
            </Button>
          ) : (
            <Button
              loading={whitelisting}
              disabled={!isAllSelected()}
              onClick={() => setShowWhitelistModal(!showWhitelistModal)}
            >
              {whitelisting ? "Please wait..." : "Whitelist"}
            </Button>
          )}
        </Group>
      </Group>
      <Space h="md" />
      {invitesLoading && (
        <Center>
          <Loader variant="dots" />
        </Center>
      )}
      {inviteError && (
        <>
          <Space h="md" />
          {inviteError.message}
        </>
      )}
      {!invitesLoading && invites && invites.length === 0 && (
        <>
          <Space h="lg" />
          <Alert
            icon={<AlertCircle size={16} />}
            title="There's no invites to show"
            color="blue"
          >
            Please upload metadata to an existing invite to see it here.
          </Alert>
        </>
      )}
      {invites && invites.length > 0 && (
        <Table striped highlightOnHover>
          <thead>
            <tr>
              <th>
                <Checkbox
                  checked={isAllSelected()}
                  onChange={(e: any) => toggleSelectAll(e?.target?.checked)}
                />
              </th>
              <th>Email</th>
              <th>Product-ID</th>
              <th>Product</th>
              <th>SKU</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {invites &&
              invites.map((inv, i) => (
                <tr key={inv.id} style={{ cursor: "pointer" }}>
                  <td>
                    {inv?.status === "PENDING_WHITELISTING" ? (
                      <Checkbox
                        checked={selected[i] ?? false}
                        onChange={(e: any) => toggleSelect(i, e.target.checked)}
                      />
                    ) : (
                      <Checkbox disabled={true} />
                    )}
                  </td>
                  <td>{inv?.email}</td>
                  <td>{inv?.item_id ?? JSON.parse(inv?.item_ids)[0]}</td>
                  <td>{inv?.name}</td>
                  <td>{inv?.sku}</td>
                  <td>{inv?.status}</td>

                  <td>
                    <Group>
                      <ActionIcon
                        onClick={() => navigate(`/invites/${inv.id}`)}
                      >
                        <ExternalLink size={16} />
                      </ActionIcon>
                    </Group>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Whitelist;
