import { doc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import { API_BASE_URI } from "./strings";

export const updateInvite = async (inviteId: string | undefined, data: {}) =>
  updateDoc(doc(db, `invites/${inviteId}`), data);

export const sendConfirmation = async (hash: string, type: string) => {
  await fetch(`${API_BASE_URI}/whitelist/confirm`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ hash, type }),
  });
};
