import AWS from "aws-sdk";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

export const getBucket = async () => {
  const cfg = await getDoc(doc(db, `meta/config`));

  AWS.config.update({
    accessKeyId: cfg.data()!["access_key_id"],
    secretAccessKey: cfg.data()!["secret_access_key"],
  });
  const bucket = cfg.data()!["s3_bucket"];
  const instance = new AWS.S3({
    params: { Bucket: bucket },
    region: cfg.data()!["s3_region"],
  });

  return { instance, bucket };
};
