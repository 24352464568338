import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ActionIcon,
  Alert,
  Button,
  Center,
  Group,
  Input,
  Loader,
  Modal,
  Space,
  Table,
  TextInput,
  Title,
} from "@mantine/core";
// import { showNotification } from "@mantine/notifications";
import { AlertCircle, ExternalLink, Search } from "tabler-icons-react";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";

const Home = () => {
  let navigate = useNavigate();
  const form = useForm({
    initialValues: {
      name: "",
      address: "",
      sku: "",
      item_id: "",
      email: "",
      owner: "",
      date_of_purchase: "",
      order: {},
    },

    validate: {
      address: (value) =>
        /^0x[a-fA-F0-9]{40}$/.test(value) ? null : "Invalid address",
    },
  });

  const [search, setSearch] = useState("");
  const [invites, setInvites] = useState<any[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [creating, setCreating] = useState(false);
  const [invitesQ, invitesLoading, inviteError] = useCollection(
    query(collection(db, `invites`), orderBy("created_at", "desc"))
  );

  useEffect(() => {
    if (invitesQ) {
      const out = [];
      for (const doc of invitesQ.docs) out.push({ ...doc.data(), id: doc.id });
      setInvites(filter(search, out));
    }
  }, [invitesQ, search]);

  const filter = (q: string, items: any[]) => {
    const out = [];
    for (const item of items) {
      if (
        item.email?.toLowerCase().includes(q.toLowerCase()) ||
        item.name?.toLowerCase().includes(q.toLowerCase()) ||
        item.sku?.toLowerCase().includes(q.toLowerCase()) ||
        item.status?.toLowerCase().includes(q.toLowerCase())
      ) {
        out.push(item);
      }
    }
    return out;
  };

  const closeWhitelistModal = () => {
    setShowCreateModal(false);
  };

  const handleOnSubmit = async (values: any) => {
    setCreating(true);
    try {
      const cfgRef = doc(db, "meta", "config");
      const cfg = await getDoc(cfgRef);
      console.log(cfg?.data()?.next_token);
      const docRef = await addDoc(collection(db, "invites"), {
        ...values,
        created_at: new Date(),
        status: "PENDING_METADATA",
        token_id: cfg?.data()?.next_token,
      });
      await updateDoc(cfgRef, {
        next_token: increment(1),
      });
      console.log("Invite ID: ", docRef.id);
      closeWhitelistModal();
      console.log(values);
    } catch (error) {
      console.log(error);
    }
    setCreating(false);
  };

  return (
    <>
      <Modal
        opened={showCreateModal}
        closeOnClickOutside={false}
        closeOnEscape={false}
        onClose={closeWhitelistModal}
        title={`Create a custom invite`}
      >
        <form onSubmit={form.onSubmit(handleOnSubmit)}>
          <TextInput
            label="Product"
            description="The name of the product"
            placeholder="Sunglasses"
            required
            {...form.getInputProps("name")}
          />
          <Space h="sm" />
          <TextInput
            label="Address"
            description="The wallet address that needs to be whitelisted"
            placeholder="0x0000000000000000000000000000000000000000"
            required
            {...form.getInputProps("address")}
          />
          <Space h="sm" />
          <TextInput
            label="Product ID"
            description="The product id"
            placeholder="6da14700-394a-428e-824d-f922b5656962"
            required
            {...form.getInputProps("item_id")}
          />
          <Space h="sm" />
          <TextInput
            label="SKU"
            description="The SKU of the product"
            placeholder="ATS21331AD"
            required
            {...form.getInputProps("sku")}
          />
          <Space h="sm" />
          <TextInput
            label="Name of the owner"
            description="The name of the owner of the product"
            placeholder="John Doe"
            required
            {...form.getInputProps("owner")}
          />
          <Space h="sm" />
          <TextInput
            label="Email"
            description="The email of the owner of the product"
            placeholder="johndoe@email.com"
            required
            type="email"
            {...form.getInputProps("email")}
          />
          <Space h="sm" />
          <DatePicker
            placeholder="Pick date"
            label="Date of purchase"
            description="The date of purchase of the product"
            required
            {...form.getInputProps("date_of_purchase")}
          />
          <Space h="sm" />

          <Group position="right" mt="md">
            <Button type="submit" disabled={creating} loading={creating}>
              Create
            </Button>
          </Group>
        </form>
        <Space h="md" />
      </Modal>
      <Space h="md" />
      <Group position="apart">
        <Title order={3}>Sent Invites</Title>
        <Group position="apart">
          <Input
            icon={<Search />}
            placeholder="Search..."
            onChange={(e: any) => setSearch(e.target.value)}
          />
          <Button onClick={() => setShowCreateModal(true)} disabled={creating}>
            Create Invite
          </Button>
        </Group>
      </Group>
      <Space h="md" />
      {invitesLoading && (
        <Center>
          <Loader variant="dots" />
        </Center>
      )}
      {inviteError && (
        <>
          <Space h="md" />
          {inviteError.message}
        </>
      )}
      {!invitesLoading && invites && invites.length === 0 && (
        <>
          <Space h="lg" />
          <Alert
            icon={<AlertCircle size={16} />}
            title="There's no invites to show"
            color="blue"
          >
            There aren't any invites to show. The invites will appear as soon as
            a customer purchases a product from your store.
          </Alert>
        </>
      )}
      {invites && invites.length > 0 && (
        <Table striped highlightOnHover>
          <thead>
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>Product-ID</th>
              <th>Product</th>
              <th>SKU</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {invites &&
              invites.map((inv, i) => (
                <tr
                  key={inv.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`invites/${inv.id}`)}
                >
                  <td>{i + 1}</td>
                  <td>{inv?.email}</td>
                  <td>{inv?.item_id ?? JSON.parse(inv?.item_ids)[0]}</td>
                  <td>{inv?.name}</td>
                  <td>{inv?.sku}</td>
                  <td>{inv?.status}</td>

                  <td>
                    <Group>
                      <ActionIcon>
                        <ExternalLink size={16} />
                      </ActionIcon>
                    </Group>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Home;
