import React, { useEffect, useState } from "react";
import { doc, DocumentData } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { db } from "../../utils/firebase";
import {
  Center,
  Grid,
  Loader,
  Paper,
  Space,
  Text,
  Title,
  FloatingTooltip,
  InputWrapper,
  Input,
  Group,
  MantineTheme,
  useMantineTheme,
  Image,
  Button,
  Textarea,
  Alert,
} from "@mantine/core";
import {
  Upload,
  Photo,
  X,
  Icon as TablerIcon,
  Check,
  CircleX,
  ExclamationMark,
  AlertCircle,
} from "tabler-icons-react";
import { Dropzone, DropzoneStatus, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import { getBucket } from "../../utils/aws";
import { updateInvite } from "../../utils/repository";
import { DatePicker } from "@mantine/dates";
import { METADATA_BASE_URI, STORE_BASE_URI } from "../../utils/strings";

const Invite = () => {
  const { id } = useParams();
  const theme = useMantineTheme();
  const [invite, setInvite] = useState<DocumentData | undefined>();
  const [image, setImage] = useState<File | undefined>();
  const [uploadingAsset, setUploadingAsset] = useState(false);
  const [progressAsset, setProgressAsset] = useState(0);
  const [uploadingMetadata, setUploadingMetadata] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dateOfPurchase, setDateOfPurchase] = useState<Date | null>();
  const [owner, setOwner] = useState("");
  const [sku, setSku] = useState("");
  const [merchant, setMerchant] = useState("");

  const [inviteQ, inviteLoading, inviteError] = useDocument(
    doc(db, `/invites/${id}`)
  );

  useEffect(() => {
    if (inviteQ) {
      setInvite(inviteQ?.data());
      setTitle(inviteQ?.data()?.title ?? "");
      setDescription(inviteQ?.data()?.description ?? "");
      setDateOfPurchase(inviteQ?.data()?.date_of_purchase?.toDate() ?? null);
      setOwner(inviteQ?.data()?.owner ?? "");
      setSku(inviteQ?.data()?.sku ?? "");
      setMerchant(inviteQ?.data()?.merchant ?? "");
    }
  }, [inviteQ]);

  const dropzoneChildren = (status: DropzoneStatus, theme: MantineTheme) => (
    <Group
      position="center"
      spacing="xl"
      style={{ minHeight: 220, pointerEvents: "none" }}
    >
      {image && (
        <Image radius="md" src={URL.createObjectURL(image)} alt="Image" />
      )}
      {!image && invite?.asset && (
        <Image
          radius="md"
          src={`https://arianoir-io.s3.amazonaws.com/${invite?.asset}`}
          alt="Image"
        />
      )}
      {!image && !invite?.asset && (
        <>
          <ImageUploadIcon
            status={status}
            style={{ color: getIconColor(status, theme) }}
            size={80}
          />
          <div>
            <Text size="xl" inline>
              Drag image here or click to select file
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              File size should not exceed 5mb
            </Text>
          </div>
        </>
      )}
    </Group>
  );

  const ImageUploadIcon = ({
    status,
    ...props
  }: React.ComponentProps<TablerIcon> & { status: DropzoneStatus }) => {
    if (status.accepted) return <Upload {...props} />;
    if (status.rejected) return <X {...props} />;
    return <Photo {...props} />;
  };

  const getIconColor = (status: DropzoneStatus, theme: MantineTheme) => {
    return status.accepted
      ? theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]
      : status.rejected
      ? theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
      : theme.colorScheme === "dark"
      ? theme.colors.dark[0]
      : theme.colors.gray[7];
  };

  const uploadAsset = async () => {
    try {
      if (!invite) return;
      setUploadingAsset(true);
      const { instance, bucket } = await getBucket();
      const key = `assets/${invite!["token_id"]}`;
      const params = {
        ACL: "public-read",
        Body: image,
        Bucket: bucket,
        ContentType: image?.type,
        Key: key,
      };
      setProgressAsset(0);
      instance
        .putObject(params)
        .on("httpUploadProgress", (evt: any) =>
          setProgressAsset(Math.round((evt.loaded / evt.total) * 100))
        )
        .send(async (err: any) => {
          if (err) {
            console.log(err);
            showNotification({
              title: "Action failed",
              message: "Asset uploading failed!",
              icon: <CircleX />,
              color: "red",
            });
          } else {
            showNotification({
              title: "Action Successful",
              message: "Asset is uploaded successfully.",
              icon: <Check />,
              color: "green",
            });
          }
          await updateInvite(id, { asset: key });
          setUploadingAsset(false);
        });
    } catch (error) {
      console.log(error);
      setUploadingAsset(false);
    }
  };

  const uploadMetadata = async () => {
    try {
      if (
        !invite ||
        !title ||
        !description ||
        !dateOfPurchase ||
        !owner ||
        !sku ||
        !merchant
      )
        return;

      if (!invite?.asset) {
        showNotification({
          title: "Action failed",
          message: "Please upload an asset first!",
          icon: <ExclamationMark />,
          color: "yellow",
        });
      }

      setUploadingMetadata(true);
      const { instance, bucket } = await getBucket();
      const key = `metadata/${invite!["token_id"]}.json`;

      let data = {
        name: title,
        description,
        edition: invite.token_id,
        image: `${METADATA_BASE_URI}/${invite?.asset}`,
        attributes: [
          {
            display_type: "date",
            trait_type: "Date of Purchase",
            value: dateOfPurchase?.valueOf(),
          },
          {
            trait_type: "Original Owner",
            value: owner,
          },
          {
            trait_type: "SKU",
            value: sku,
          },
        ],
      };
      let fileToSave = new Blob([JSON.stringify(data)], {
        type: "application/json",
      });

      const params = {
        ACL: "public-read",
        Body: fileToSave,
        Bucket: bucket,
        ContentType: "application/json",
        Key: key,
      };

      instance
        .putObject(params)
        .on("httpUploadProgress", (evt: any) =>
          setProgressAsset(Math.round((evt.loaded / evt.total) * 100))
        )
        .send(async (err: any) => {
          if (err) {
            console.log(err);
            showNotification({
              title: "Action failed",
              message: "Metadata uploading failed!",
              icon: <CircleX />,
              color: "red",
            });
          } else {
            await updateInvite(id, {
              metadata: key,
              title,
              description,
              date_of_purchase: dateOfPurchase,
              owner,
              sku,
              merchant,
              status: "PENDING_WHITELISTING",
            });
            showNotification({
              title: "Action Successful",
              message: "Metadata is uploaded successfully.",
              icon: <Check />,
              color: "green",
            });
          }
          setUploadingMetadata(false);
        });
    } catch (error) {
      console.log(error);
      setUploadingMetadata(false);
    }
  };

  const getItemId = () => {
    try {
      if (invite?.item_ids) {
        return JSON.parse(invite?.item_ids)[0];
      }
    } catch (error) {
      console.log(error);
    }
    return invite?.item_id;
  };

  return (
    <>
      <Space h="md" />
      <Title order={3}>Invitation</Title>
      <Space h="sm" />
      <Paper shadow="xs" p="md" withBorder>
        {inviteLoading && (
          <Center>
            <Loader variant="dots" />
          </Center>
        )}
        {inviteError && <div>{inviteError.message}</div>}
        {!inviteLoading && !inviteError && (
          <Grid>
            <Grid.Col span={4}>
              <Text weight={700}>Email:&nbsp;</Text>
              <Text>{invite?.email}</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <FloatingTooltip label={`Product: ${getItemId()}`} radius="md">
                <Text weight={700}>Product:&nbsp;</Text>
                <Text>
                  <a
                    rel="noreferrer"
                    href={`${STORE_BASE_URI}/product-page/${getItemId()}`}
                    target="_blank"
                  >
                    {invite?.name}
                  </a>
                </Text>
              </FloatingTooltip>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text weight={700}>Status:&nbsp;</Text>
              <Text>{invite?.status}</Text>
            </Grid.Col>
          </Grid>
        )}
      </Paper>
      {invite && invite.status === "PENDING_ADDRESS" && (
        <>
          <Space h="lg" />
          <Alert
            icon={<AlertCircle size={16} />}
            title="There's no wallet connected by the customer yet!"
            color="yellow"
          >
            In order to continue with metadata creation and asset upload, the
            customer must connect a wallet to the application.
          </Alert>
        </>
      )}
      {invite && invite.status === "WHITELISTED" && invite.status === "GIFTED" && (
        <>
          <Space h="lg" />
          <Alert
            icon={<AlertCircle size={16} />}
            title="After an invite is whitelisted or gifted, the metadata should not be updated."
            color="orange"
          >
            You can update the metadata and if you decide to update anyway,
            please make sure to refresh metadata within the OpenSea collection
            page after saving the data.
          </Alert>
        </>
      )}
      {invite && invite["status"] !== "PENDING_ADDRESS" && (
        <>
          <Space h="xl" />
          <Group position="apart">
            <Title order={3}>Asset</Title>
            <Button
              loading={uploadingAsset}
              disabled={!image}
              onClick={uploadAsset}
            >
              {uploadingAsset ? `Uploading - ${progressAsset}%` : "Save"}
            </Button>
          </Group>
          <Space h="sm" />
          <Dropzone
            onDrop={(files) => setImage(files[0])}
            onReject={(_) => setImage(undefined)}
            accept={IMAGE_MIME_TYPE}
            disabled={inviteLoading}
          >
            {(status) => dropzoneChildren(status, theme)}
          </Dropzone>
          <Space h="xl" />
          <Group position="apart">
            <Title order={3}>Metadata</Title>
            <Button loading={uploadingMetadata} onClick={uploadMetadata}>
              Save
            </Button>
          </Group>
          <Space h="sm" />
          <Paper shadow="xs" p="md" withBorder>
            <InputWrapper
              id="name"
              required
              label="Name"
              description="Give a name for the NFT. This will be displayed within NFT marketplaces."
            >
              <Input
                id="name"
                placeholder="ARCA | 309"
                disabled={inviteLoading}
                onChange={(e: any) => setTitle(e.target.value)}
                value={title}
              />
            </InputWrapper>
            <Space h="lg" />
            <InputWrapper
              id="description"
              required
              label="Description"
              description="Give a description for the NFT. This will be displayed within NFT marketplaces."
            >
              <Textarea
                id="description"
                placeholder="Arca 309 is a perfect addition to any outfit."
                disabled={inviteLoading}
                minRows={3}
                autosize
                onChange={(e: any) => setDescription(e.target.value)}
                value={description}
              />
            </InputWrapper>
            <Space h="lg" />
            <InputWrapper
              id="purchase-date"
              required
              label="Date of purchase"
              description="Select the date that the product has been purchased."
            >
              <DatePicker
                id="purchase-date"
                placeholder="Pick date"
                disabled={inviteLoading}
                onChange={(date) => setDateOfPurchase(date)}
                value={dateOfPurchase}
              />
            </InputWrapper>
            <Space h="lg" />
            <InputWrapper
              id="owner"
              required
              label="Original Owner"
              description="Please enter the name of the original owner of this product."
            >
              <Input
                id="owner"
                placeholder="@JohnDoe213"
                disabled={inviteLoading}
                onChange={(e: any) => setOwner(e.target.value)}
                value={owner}
              />
            </InputWrapper>
            <Space h="lg" />
            <InputWrapper
              id="sku"
              required
              label="SKU"
              description="Please enter the SKU of this product."
            >
              <Input
                id="sku"
                placeholder="XXXXXXXXXXXX"
                disabled={inviteLoading}
                onChange={(e: any) => setSku(e.target.value)}
                value={sku}
              />
            </InputWrapper>
            <Space h="lg" />
            <InputWrapper
              id="merchant"
              required
              label="Merchant Name"
              description="Please enter the merchant name of this product."
            >
              <Input
                id="merchant"
                placeholder="John Doe"
                disabled={inviteLoading}
                onChange={(e: any) => setMerchant(e.target.value)}
                value={merchant}
              />
            </InputWrapper>
          </Paper>
          <Space h="xl" />
        </>
      )}
    </>
  );
};

export default Invite;
